import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import {
  getElementPosition,
  getScrollBottomPosition,
} from 'utils/scroll';

import BasketContinueFloating from './BasketContinueFloating';

export default class BasketContinueFloatingContainer extends Component {
  state = {
    render: false,
    show: false,
    elementPosition: 0,
  }

  shouldShow = () => {
    const elementHeight = -80;
    const scrollPosition = getScrollBottomPosition() + elementHeight;
    const elementPosition = getElementPosition('.BasketContinue-actions');
    return scrollPosition < elementPosition;
  }

  show = () => {
    if (!this.state.show) {
      this.setState({
        render: true,
        show: true,
      });
    }
  }

  hide = () => {
    if (this.state.show) {
      this.setState({
        show: false,
      });
    }
  }

  update = () => {
    if (this.shouldShow()) {
      this.show();
    } else {
      this.hide();
    }
  }

  handleScroll = debounce(() => {
    this.update();
  });

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.update();
  }

  componentWillReceiveProps() {
    this.update();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  preventInitialFlashAnimation = () => this.state.render || null;

  render () {
    return this.preventInitialFlashAnimation() && (
      <BasketContinueFloating
        zipcode={this.props.zipcode}
        handleClick={this.props.handleClick}
        productCount={this.props.productCount}
        disabled={this.props.disabled}
        totals={this.props.totals}
        hasFreight={this.props.hasFreight}
        show={this.state.show}
      />
    );
  }
}

BasketContinueFloatingContainer.propTypes = {
  handleClick: PropTypes.func.isRequired,
  productCount: PropTypes.number.isRequired,
  totals: PropTypes.shape({
    to: PropTypes.string.isRequired,
    cash: PropTypes.string,
    shipment: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  hasFreight: PropTypes.bool,
  zipcode: PropTypes.string
};

BasketContinueFloatingContainer.defaultProps = {
  disabled: false,
  hasFreight: false,
};
