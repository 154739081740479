import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isLogged, getCurrentStep } from 'utils/session';
import { optionClick as clickEvent } from 'reducers/navigation';
import {
  basket as routeBasket,
  login as routeLogin,
  signup as routeSignup,
  editRegistration as routeEditRegistration,
  delivery as routeDelivery,
  address as routeAddress,
  payment as routePayment,
} from 'constants/route-names';
import { statusColors } from 'constants/checkout-shortcuts-status';
import NavigationIcon from '../NavigationIcon';
import './styles.scss';

const basketPage = 'Sacola';
const basketLogin = 'Identificação';
const basketDelivery = 'Entrega';
const basketPayment = 'Pagamento';

const pageName = {
  [routeBasket]: basketPage,
  [routeLogin]: basketLogin,
  [routeSignup]: basketLogin,
  [routeEditRegistration]: basketLogin,
  [routeDelivery]: basketDelivery,
  [routeAddress]: basketDelivery,
  [routePayment]: basketPayment,
};

const mapDispatchToActions = {
  optionClick: clickEvent
};

@connect(undefined, mapDispatchToActions)
export default class NavigationOption extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static propTypes = {
    routeName: PropTypes.string.isRequired,
    optionClick: PropTypes.func.isRequired,
    status: PropTypes.any.isRequired,
  };

  routeToNavigate = () => {
    const { routeName, optionClick } = this.props;

    optionClick(routeName, getCurrentStep());

    if (routeName === routeBasket) {
      return '';
    }

    return isLogged() ? routeName : routeLogin;
  };

  handleNavigation = () => {
    const { router } = this.context;

    const path = this.routeToNavigate();

    if (router) {
      router.push(path);
      this.forceUpdate();
    } else if (path !== 'entrega') {
      window.location.assign(`/#/${path}`);
    }
  };

  render() {
    const { routeName, status } = this.props;
    const statusName = Object.entries(statusColors).find(
      (properties) => properties[1] === status[routeName]
    )[0];

    const buttonClassName = `NavigationOption-button-${statusName}`;
    const progressClassName = `NavigationOption-progress-${statusName}`;
    const titleClassName = `NavigationOption-title-${statusName}`;
    const routeTitleClassName = `NavigationOption-title-${routeName}`;

    return (
      <div className="NavigationOption-container">
        <div className="NavigationOption-shortcut">
          <button
            className={`NavigationOption-button ${buttonClassName}`}
            onClick={this.handleNavigation}
            title={routeName === routeLogin ? 'identificação' : routeName}
            id={`button-${routeName}`}
            disabled={statusName === 'current'}
          >
            <NavigationIcon routeName={routeName} status={status} />
          </button>
          {routeName !== routePayment && (
            <div className={`NavigationOption-progress ${progressClassName}`} />
          )}
        </div>
        <span
          className={`NavigationOption-title ${titleClassName} ${routeTitleClassName}`}>
          {pageName[routeName]}
        </span>
      </div>
    );
  }
}
